import React from 'react';
import '../styles/Global.css';
import '../styles/ComingSoon.css';

const ComingSoon = () => {
  return (
    <div className="coming-soon-container">
      <h1 className="coming-soon-title">Coming Soon</h1>
      <p className="coming-soon-description">We're working hard to bring you something amazing. Stay tuned!</p>
    </div>
  );
};

export default ComingSoon;