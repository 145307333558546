import React from 'react';
import logo from './logo.svg';
import './App.css'; // Import your global or component-specific CSS here

// Import existing components
import CombinedComponent from './Components/CombinedComponent';
import PhotoCollection from './Components/PhotoCollection';
import MeetTheTeam from './Components/MeetTheTeam';
import CustomerReviews from './Components/CustomerReviews';
import ContactUs from './Components/ContactUs';
import FAQ from './Components/FAQ';
import DownloadApp from './Components/DownloadApp';
import Footer from './Components/Footer';
import DemoPage from './Pages/DemoPage/DemoPage.js';
import HowItWorks from './Components/HowItWorks.js';
import Features from './Components/Features.js';
import Waves from './Components/Waves.js';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import FeaturesCarousel from './Components/FeaturesCarousel';
import Testimonials from './Components/Testimonials';
import ComingSoon from './Components/ComingSoon';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={
        <div className="App">

          <ComingSoon />

          {/* Header Navigation */}
           {/* <CombinedComponent /> */}

           {/* <Waves /> */}

           {/* <Features /> */}

           {/* <HowItWorks /> */}

          {/* Testimonials */}
          {/* <Testimonials /> */}

          {/* Feature Carousel */}
          {/* <FeaturesCarousel /> */}

          {/* Photo Collection */}
          {/* <PhotoCollection id="photo-collection" /> */}

          {/* Meet the Team */}
          {/* <MeetTheTeam id="meet-the-team" /> */}

          {/* Customer Reviews */}
          {/* <CustomerReviews id="customer-reviews" /> */}

          {/* Header */}
          {/* <HeaderNavigation /> */}

          {/* Main Content 
          <MainContent id="main-content" />*/}

          {/* Contact Us */}
          {/* <ContactUs id="contact-us" /> */}

          {/* FAQ */}
          {/* <FAQ id="faq" /> */}

          {/* Download App */}
          {/* <DownloadApp />  */}

          {/* Download App */}
          {/* <Footer />  */}
        </div>
      } />
      <Route path="/demo" element={<DemoPage />} />
    </Routes>
    </Router>
  );
}

export default App;
