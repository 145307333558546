// Footer.js
import React from 'react';
import { Link } from 'react-scroll';
import '../styles/Footer.css';

const Footer = () => {
  return (
    <div>
      <div className="footer-container">
        <div className="footer-section about-us">
          <h4>About Us</h4>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed commodo, sapien vel volutpat lacinia, erat odio blandit turpis. Vestibulum fringilla turpis eu augue tristique, ac varius odio efficitur.</p>
        </div>
        <div className="footer-section contact-info">
          <h4>Contact Information</h4>
          <p>Email: info@sparkanova.com</p>
          <p>Phone: +1 (555) 123-4567</p>
          <p>Address: 123 Main Street, Cityville, USA</p>
        </div>
        <div className="footer-section follow-us">
          <h4>Follow Us</h4>
          <p>Stay connected with us on social media:</p>
          <div className="social-icons"></div>
        </div>
      </div>
      {/* New section */}
      <div className="footer-section copyrights">
        <h4>&copy; Copyrights 2024. All rights reserved.</h4>
      </div>
    </div>
  );
};

export default Footer;
