// HeaderNavigation.js
import React from 'react';
import { Link } from 'react-router-dom';
import logoPath from '../assets/pepper-1.jpeg'; // Import the image directly
import '../styles/HeaderNavigation.css'; // Import the external CSS file

const HeaderNavigation = () => {
  return (
    <div className="header-container">
      <div className="logo-container">
        <img src={logoPath} alt="Logo" className="logo" />
        <h1 className="header-text">HueSpicy</h1>
      </div>
      <div className="navigation">
        <Link to="/" className="nav-link">Billing</Link>
        <Link to="/demo" className="nav-link">Demo</Link>
        <button className="button">Login</button>
      </div>
    </div>
  );
};

export default HeaderNavigation;