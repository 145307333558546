// DemoPage.js
import React from 'react';
import HeaderNavigation from '../../Components/HeaderNavigation';
import Footer from '../../Components/Footer';

const DemoPage = () => {
  return (
    <div>
      <HeaderNavigation />
      <div className="demo-content">
        {/* Add sample content and functionality for the Demo Page */}
        <h1>Demo Page</h1>
        <p>This is a demo page showcasing the features of our software.</p>
        {/* Add more content as needed */}
      </div>
      <Footer />
    </div>
  );
};

export default DemoPage;